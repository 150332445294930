
import {Vue, Component, Prop, PropSync, Watch} from 'vue-property-decorator'
import SortInput from '@/components/ui/SortInput'
import Checkbox from '@/components/ui/Checkbox'
import type {TSortInputValue} from '@/components/ui/SortInput/SortInput.vue'

export type TDataTableConstruction = {
  name: string
  title: string
  sortable: boolean
  basis: string
}
export type TDataTableConstructionSortable = TDataTableConstruction & {sort?: TSortInputValue}

export type TSortCb = (a: any, b: any) => number
export type TSortRules = {
  ascending: TSortCb
  descending: TSortCb
}
export type TSortRecord = Record<string, TSortRules>

@Component({
  components: {
    SortInput,
    Checkbox
  }
})
export default class DataTable extends Vue {
  @Prop(Boolean) readonly selectable: boolean

  @Prop({type: Array, default: () => []})
  readonly construction: TDataTableConstruction[]

  @Prop({type: String, default: ''})
  readonly idField!: string

  @Prop({type: Array, default: () => []})
  readonly data: Record<string, string>[]

  @Prop({type: Object, default: null})
  readonly sortRules?: TSortRecord

  sortFields: string[] = []
  sortCurrent: string = ''
  sortMethod: TSortInputValue = null

  fields: string[] = []
  headings: TDataTableConstructionSortable[] = []
  basises: Record<string, string> = {}
  selected: (string | number)[] = []
  preparedData: Record<string, string>[] = []

  get isContextMenuVisible () {
    return !!this.selected.length
  }

  get isActiveSelectedState () {
    return !!this.selected.length
  }

  get isIndeterminatedSelectState () {
    return this.isActiveSelectedState && (this.data.length !== this.selected.length)
  }

  get sortedData () {
    if (this.sortRules && this.sortCurrent && this.sortMethod) {
      const rule = this.sortRules[this.sortCurrent]
      const method = rule[this.sortMethod]

      return [...this.data].sort(method)
    }

    return this.data
  }

  @Watch('data')
  onDataChanged () {
    this.resetDataModifiers()
  }

  created () {
    this.build()
  }

  build () {
    const _fields: string[] = []
    const _headings = []
    const _basises: Record<string, string> = {}

    for (const item of this.construction) {
      _fields.push(item.name)
      _headings.push({
        ...item,
        ...(item.sortable ? {sort: null} : {})
      })
      _basises[item.name] = item.basis
    }

    this.fields = _fields
    this.headings = _headings
    this.basises = _basises

    if (this.sortRules) {
      this.sortFields = Object.keys(this.sortRules)
    }
  }

  toggleSelect (id: string | number) {
    if (this.selected.includes(id)) {
      this.selected = this.selected.filter(_id => _id !== id)
    } else {
      this.selected.push(id)
    }
  }

  resetDataModifiers () {
    this.selected = []
    this.sortCurrent = ''
    this.sortMethod = null
    this.headings = this.headings.map(item => ({
      ...item,
      sort: null
    }))
  }

  onMainSelectClick () {
    const dataLength = this.data.filter(item => item.status === 'enabled' || item.status === 'disabled').length

    if (
      !this.selected.length ||
      ((this.selected.length > 0) && (this.selected.length < dataLength))
    ) {
      // if selected is empty or is indeterminate - select all
      this.selected = this.data
        .filter(item => item.status === 'enabled' || item.status === 'disabled')
        .map(item => item[this.idField])
    } else if (this.selected.length === dataLength) {
      // if is all rows is selected - uncheck all
      this.selected = []
    }
  }

  onSortInputClick (index: number, val: TSortInputValue, key: string) {
    this.headings.forEach((item, _i) => {
      if (_i !== index) {
        item.sort = null
      } else {
        this.sortMethod = val
        this.sortCurrent = key
      }
    })
  }

  onSelectAll () {
    this.selected = this.data
      .filter(item => item.status === 'enabled' || item.status === 'disabled')
      .map(item => item[this.idField])
  }

  onRemoveSelectionAll () {
    this.selected = []
  }
}
